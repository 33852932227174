import { render, staticRenderFns } from "./CommentManage.vue?vue&type=template&id=de96d2cc&"
import script from "./CommentManage.vue?vue&type=script&lang=js&"
export * from "./CommentManage.vue?vue&type=script&lang=js&"
import style0 from "./CommentManage.vue?vue&type=style&index=0&id=de96d2cc&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("d:\\workspace\\liveinfo3-front-admin-dev\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('de96d2cc')) {
      api.createRecord('de96d2cc', component.options)
    } else {
      api.reload('de96d2cc', component.options)
    }
    module.hot.accept("./CommentManage.vue?vue&type=template&id=de96d2cc&", function () {
      api.rerender('de96d2cc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/apps/contents/comment-manage/CommentManage.vue"
export default component.exports